import React from 'react';
import {
    HashRouter as Router,
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';

import BearwaveHeader from "./components/header/Header";
import AppsComponent from "./components/appsComponent/AppsComponent";
import TechComponent from "./components/tech/TechComponent";
import BeartoonGallery from "./components/gallery/BeartoonGallery";

import './App.css';

function App() {
    return (
        <Router>
            <div className="app">
                <BearwaveHeader />
                <Switch>
                    <Route exact path="/tech"><TechComponent /></Route>
                    <Route exact path="/apps"><AppsComponent /></Route>
                    <Route path="/toons"><BeartoonGallery /></Route>
                    <Redirect to="/tech" />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
