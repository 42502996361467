import axios from 'axios';

export interface Series {
    id: number;
    created: Date;
    title?: string;
    beartoons: Beartoon[];
}

export interface Beartoon {
    id: number;
    created: Date;
    sequence: number;
    caption?: string;
    series?: number;
    image: string;
}

export const fetchSeries = async (): Promise<Series[]> =>
    await axios.get('/api/series/').then(resp => resp.data);

export const fetchBeartoons = async (): Promise<Beartoon[]> =>
    await axios.get('/api/beartoons/').then(resp => {
        return resp.data.map((b: Beartoon) => {
            const d = new Date(b.created);
            return {
                ...b,
                created: new Date(d),
            };
        });
    });

