import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import classNames from 'classnames';

import {Series, Beartoon, fetchBeartoons, fetchSeries} from "./galleryApi";

interface BeartoonGalleryState {
    loading: boolean;
    loaded: boolean;
    series: any;
    currentToonIndex: number,
    currentSeries: number,
    visitedSeriesIds: number[],
    visitedUnseriesedToons: number[],
    previous: any,
}

const initialState: BeartoonGalleryState = {
    loading: false,
    loaded: false,
    series: new Map<number, Series>(),
    currentToonIndex: 0,
    currentSeries: 0,
    visitedSeriesIds: [],
    visitedUnseriesedToons: [],
    previous: {},
}


const mapSeriesState = (series: Series[], beartoons: Beartoon[]) => {
    const allSeries = _.chain(series)
        .map(s => {
            return {
                ...s,
                beartoons: [],
            }
        })
        .keyBy('id')
        .value();
    const map = new Map<number, Series>();
    _.forEach(beartoons, b => {
        const series_id = (!b.series) ? 0 : b.series;
        const existingSeries = map.get(series_id) || allSeries[series_id] || {};
        const existingBeartoons = existingSeries.beartoons || [];
        const newBeartoons = _.chain(existingBeartoons.concat(b)).uniq().orderBy(['sequence'], ['asc']).value();
        map.set(series_id, {
            ...existingSeries,
            beartoons: newBeartoons,
        });
    });
    return map;
};


const randomNumber = (max: number) =>
    Math.floor(Math.random() * Math.floor(max));

const randomNextSeries = (numSeries: number) => {
    const rand = randomNumber(numSeries * 6);
    return (rand >= numSeries) ? 0 : rand;
};


const prevToonState = (state: BeartoonGalleryState) => {
    if (state.currentSeries > 0 && state.currentToonIndex - 1 >= 0) {
        return {
            ...state,
            currentToonIndex: state.currentToonIndex - 1,
        };
    } else {
        return {...state};
    }
};

const nextToonState = (state: BeartoonGalleryState) => {
    const currentSeries = state.series.get(state.currentSeries);
    const maxToons = currentSeries.beartoons.length;
    const numSeries = state.series.size - 1; // subtract for "no series" at index 0

    // prevent infinite loops if all have been cycled
    if (state.visitedSeriesIds.length >= numSeries -1 && state.visitedUnseriesedToons >= state.series.get(0).size) {
        return {
            ...state,
            currentSeries: 0,
            currentToonIndex: 0,
            visitedSeriesIds: [],
            visitedUnseriesedToons: [],
            previous: {
                series: state.currentSeries,
                sequence: state.currentToonIndex,
            },
        }
    }

    if (state.currentSeries === 0 || (state.currentSeries > 0 && state.currentToonIndex + 1 >= maxToons)) {

        let nextSeries = randomNextSeries(numSeries);
        if (state.visitedSeriesIds.length >= numSeries -1) {
            nextSeries = 0;
        } else {
            while (state.visitedSeriesIds.length < numSeries && _.includes(state.visitedSeriesIds, nextSeries)) {
                nextSeries = randomNextSeries(numSeries);
            }
        }

        let nextToon = randomNumber(maxToons);
        if (nextSeries === 0) {
            while (state.visitedUnseriesedToons.length < maxToons && _.includes(state.visitedUnseriesedToons, nextToon)) {
                const unseriesed = state.series.get(0);
                nextToon = randomNumber(unseriesed.beartoons.length);
            }
        } else {
            nextToon = 0;
        }

        let updatedSeriesIds = state.visitedSeriesIds;
        let updatedUnseriesedToons = state.visitedUnseriesedToons;
        if (nextSeries === 0) {
            updatedUnseriesedToons = state.visitedUnseriesedToons.concat([state.currentToonIndex]);
        } else {
            updatedSeriesIds = state.visitedSeriesIds.concat([nextSeries]);
        }

        return {
            ...state,
            currentSeries: nextSeries,
            currentToonIndex: nextToon,
            visitedSeriesIds: updatedSeriesIds,
            visitedUnseriesedToons: updatedUnseriesedToons,
            previous: {
                series: state.currentSeries,
                sequence: state.currentToonIndex,
            },
        };
    } else if (state.currentToonIndex + 1 < maxToons) {
        return {
            ...state,
            currentToonIndex: state.currentToonIndex + 1,
            previous: {
                series: state.currentSeries,
                sequence: state.currentToonIndex,
            },
        };
    } else {
        const visitedSeries = (state.currentSeries > 0) ? [state.currentSeries] : [];

        const numSeries = state.series.size;

        let nextSeries = randomNextSeries(numSeries);
        while (state.visitedSeriesIds.length < numSeries && _.includes(state.visitedSeriesIds, nextSeries)) {
            nextSeries = randomNextSeries(numSeries);
        }
        return {
            ...state,
            currentSeries: nextSeries,
            currentToonIndex: 0,
            visitedSeriesIds: state.visitedSeriesIds.concat(visitedSeries),
            previous: {
                series: state.currentSeries,
                sequence: state.currentToonIndex,
            },
        };
    }
};

const hasPreviousToon = (state: BeartoonGalleryState) => {
    return (state.currentSeries > 0 && state.currentToonIndex - 1 >= 0);
};

const hasNextToon = (state: BeartoonGalleryState) => {
    if (state.currentSeries > 0) {
        const currentSeries = state.series.get(state.currentSeries);
        const maxToons = currentSeries.beartoons.length;
        return (state.currentToonIndex + 1 < maxToons);
    } else {
        return false;
    }
};

const renderSeriesTitle = (state: BeartoonGalleryState) => {
    if (!state.series.size) return (<span/>);
    const series = state.series.get(state.currentSeries);
    const currentToon = state.series.get(state.currentSeries).beartoons[state.currentToonIndex];
    return state.currentSeries > 0 && series
        ? (<span>{dayjs(series.created).format('MMM DD, \'YY')} {series.title}</span>)
        : (<span>{dayjs(currentToon.created).format('MMM DD, \'YY')}</span>);
};

// @ts-ignore
const BeartoonComponent = ({series, current}) => {
    if (!series) return null;
    return (
        <div className="beartoon comic-cell">
            <div className="hero">
                <div className="image-container">
                    <img src={series.beartoons[current].image}
                         alt={series.beartoons[current].caption}
                         width="100%"/>
                </div>
            </div>
            <div className="captions">
                {series.beartoons[current].caption}
            </div>
        </div>
    );
}

const getFirstSeriesAndToon = (seriesMap: Map<number, Series>, beartoons: Beartoon[]): any => {
    if (!seriesMap.size || !beartoons.length) return { firstSeries: -1, firstToon: -1 };
    const sortedByCreated = _.orderBy(beartoons, ['created'], ['desc']);
    const latest = _.head(sortedByCreated);

    let latestSeries = 0, latestBeartoon = 0;
    if (latest && latest.series) {
        latestSeries = latest.series;
    } else {
        // @ts-ignore
        const unseriesed = seriesMap.get(0).beartoons;
        // @ts-ignore
        latestBeartoon = _.findIndex(unseriesed, t => t.id === latest.id);
    }

    return {
        series: latestSeries,
        beartoon: latestBeartoon,
    }
}

const BeartoonGallery = () => {
    let [state, setState] = useState<BeartoonGalleryState>(initialState);

    useEffect(() => {
            if (!state.loading) {
                if (!state.loaded) {
                    setState({
                        ...state,
                        loading: true,
                        loaded: false,
                    })

                    Promise.all([
                        fetchBeartoons(),
                        fetchSeries()
                    ]).then((res: any[]) => {
                        const bs = res[0];
                        const ss = res[1];

                        const seriesMap = mapSeriesState(ss, bs);

                        const first = getFirstSeriesAndToon(seriesMap, bs);

                        setState({
                            ...state,
                            loading: false,
                            loaded: true,
                            series: seriesMap,
                            currentToonIndex: first.beartoon,
                            currentSeries: first.series,
                            visitedSeriesIds: first.series > 0 ? [first.series] : [],
                            visitedUnseriesedToons: [],
                            previous: {
                                series: first.series,
                                sequence: first.beartoon,
                            },
                        })
                    }).catch((err) => {
                        console.error('ERROR', err);
                    });
                }
            }
        }
        ,
        [state]
    );

    return (
        <div className="beartoons">
            <div className="stage">
                <div className="controls">
                    <div className={classNames("nav-button left", {disabled: !hasPreviousToon(state)})}>
                        <button onClick={() => setState(prevToonState(state))}>
                            &laquo; Prev
                        </button>
                    </div>
                    <div className="nav-button right">
                        <button onClick={() => setState(nextToonState(state))}>
                            {hasNextToon(state)
                                ? (<span>Next &raquo;</span>)
                                : (<span>Speen!</span>)}
                        </button>
                    </div>
                </div>

                <div className="series-info">
                    <div className="series-title">
                        {renderSeriesTitle(state)}
                    </div>
                    <div className="spacer">&nbsp;</div>
                    <div className="sequence">
                        {state.currentSeries > 0 && state.series.get(state.currentSeries) && (
                            <span>
                                <span className="num">{state.currentToonIndex + 1}</span> /
                                <span className="total">{state.series.get(state.currentSeries).beartoons.length}</span>
                            </span>
                        )}
                    </div>

                </div>
                <BeartoonComponent series={state.series && state.series.get(state.currentSeries)}
                                   current={state.currentToonIndex}/>
            </div>

        </div>
    );
}

export default BeartoonGallery;