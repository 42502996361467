import React from 'react';

const AppsComponent = () => {
    return (
        <div className="apps-page copy-page">
            <h1>Apps</h1>

            <p>Submitted for your approval... some random tinkering projects.</p>

            <div className="background-tab-search app-listing-container comic-cell">
                <h2>
                    www.bearwavetech.com
                    <span className="side-note">(you're lookin at it)</span>
                </h2>
                <p>
                    The site is meant to be straightforward, and a culmination of techniques and tools I've been using
                    over the past few years.  It's not much, but I'm fairly proud of this site. The bulk of it was done
                    in a single day with extensive use of popular frameworks.
                </p>
                <p><span className="strong">Day 1</span> started at 11am and ended at 5am the following morning.</p>
                <ul className="list">
                    <li>Set up the base tech stack using <code>docker-compose</code>: Django, Postgres, React.</li>
                    <li>Implemented the initial data model and db schemas.</li>
                    <li>Implemented REST endpoints.</li>
                    <li>Wrote a NodeJS script that uses <code>imagemagick</code> (<span className="strong">New!</span>) to process ~500 beartoons images from Apple's .HEIC format to be similar dimensions and a consistent .PNG format.</li>
                    <li>Implemented CSV importer to handle images and metadata and populate the beartoons database.</li>
                    <li>Configured <code>create-react-app</code> to use the <code>typescript</code> (<span className="strong">New!</span>) template.</li>
                    <li>Implemented initial beartoons viewer with typescript components and React hooks - I usually write functional-style components using <code>react-redux</code> but I wanted to give hooks a try.</li>
                    <li>Built the initial site design to prioritize mobile devices.</li>
                </ul>
                <p>whew~</p>
                <p><span className="strong">Day 2</span> involved compiling a production version of the backend/frontend, setting up a new server, and deploying the initial version.</p>
                <p><span className="strong">Day 3</span> was a few rounds of tweaking the look and feel, adding navigation, additional pages like this one, and repeating.</p>
                <p><span className="italic">Et voila, a wild bearwavetech.com appears!!</span></p>
            </div>

            <div className="background-tab-search app-listing-container comic-cell">
                <h2>
                    <a
                        href="https://chrome.google.com/webstore/detail/background-tab-search/gklomnnnnjcapcllpfopcljbjmbkngpl"
                        target="_blank"
                        rel="noreferrer">Background Tab Search</a>
                    <span className="side-note">Chrome Extension</span>
                </h2>
                <p>
                    If you ever go down a wikipedia/Google rabbit hole, and find every page you come across causes you
                    to
                    search for another concept in another tab, this extension lets you do your search without switching
                    to that new tab and interrupting your reading focus. Works even better if you activate it with a
                    keyboard macro.
                </p>
                <p>
                    Alternatively, you could use your regular URL bar to type your search and hit <code>alt + shift +
                    enter</code>.
                </p>
            </div>
        </div>
    );
};

export default AppsComponent;