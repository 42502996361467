import React from 'react';

const TechComponent = () => {
    return (
        <div className="tech-page copy-page">
            <h1>Tech (consulting)</h1>

            <p>
                Need some technology made? Let's chat! Whether you're a small start-up looking for guidance on
                building a website/app, an academic institution building out an in-house team, or a large enterprise
                looking
                for hands-on expertise, this is the right place to look.
            </p>

            <ul className="contact-methods">
                <li><a href="https://www.linkedin.com/in/myclues/" target="_blank" rel="noreferrer">LinkedIn</a></li>
                <li><a href="https://twitter.com/bearwavetech" target="_blank" rel="noreferrer">@bearwavetech</a></li>
            </ul>

            <div className="grid grid-2col">
                <div>
                    <h2>Technology consulting services</h2>
                    <ul className="list">
                        <li>system architecture &amp; design</li>
                        <li>cloud infrastructure architecture &amp; implementation</li>
                        <li>system monitoring</li>
                        <li>full-stack development</li>
                        <li>rapid prototyping</li>
                        <li>data analytics collection &amp; analysis</li>
                    </ul>
                </div>
                <div>
                    <h2>Bread and butter (mmm...) tech stacks:</h2>
                    <ul className="list">
                        <li>Linux - Ubuntu, Amazon, Debian</li>
                        <li>Amazon AWS, Microsoft Azure</li>
                        <li>SQL, MongoDB, various no-SQL</li>
                        <li>RabbitMQ, MQTT, AWS Kinesis, Azure ServiceBus</li>
                        <li>Elastic ELK stack</li>
                        <li>NodeJS, React/Redux, Typescript, ExpressJS</li>
                        <li>Python, Django, Flask</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TechComponent;
